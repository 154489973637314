import { 
  Box, 
  Grid, 
  Typography 
} from "@mui/material"
import Page from "components/Page"
import { Link } from "react-router-dom"

const LandingPage = () => {
  return (
    <Page>
      <Grid container spacing={3}>
      <Grid item md={12}>
      <Box>
        <Link to="/builder"><Typography variant="h1" fontSize={38}>Dots Experence One: Composites</Typography></Link>
      </Box>
      </Grid>
      <Grid item xs={12}>
      <Box position='relative' paddingBottom={'56.25%'}>
        <iframe title='DOTS: Teaser' src="https://www.youtube.com/embed/OA5vi40dnZ0?si=jCBABqhL2Lvhxsne&modestbranding=1" style={{position: 'absolute', border: '0', width: '100%', height: '100%'}} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
      </Box>
      </Grid>
      <Grid item xs={12}>
      <Box mb={20}>
        <Typography>Dots is the beginning of a new generative art ecosystem envisioned by r4v3n. The story starts with a new “Inclusive Edition” launching on Prohibition and Floor on 1/24/24 powered by Art Blocks Engine. Following in the footsteps of Snowfro and Jordan Lyall’s “heart + craft”, “Dots” aims to provide high quality on-chain generative art at a price point that is accessible for everyone. </Typography>
        <Typography mt={2}>
At its core the art behind “Dots” is a distillation of the soul of r4v3n’s two Art Blocks projects “Moments” and “Pieces of Me” released in 2021. Code based paint strokes are rotated around a center point making a simple dot. This singular dot serves as an atomic unit, a fundamental building block in r4v3n's artistic language. It represents a starting point from which boundless creative possibilities can emerge.</Typography>
        <Typography mt={2}>What makes "Dots" so exciting is its potential for collaborative creation. While each dot stands alone as a complete piece, these dots can be used to mint other experiences that will take on the character of the dots passed in at mint time.</Typography>
        <Typography mt={2}>
        The first experience in the “Dots” generative ecosystem is the “Dots: Composites” builder. This is a tool allows collectors to use their dots to mint a limited edition series that will combine their dots into a larger composition. This will allow collectors to curate 12 or more dots which they can pass into the composite minter, allowing them to participate with r4v3n in the creation of the final pieces. Collectors also have the ability to burn their composites and claim back the “Dots” they used to mint them, allowing for them to use the dots in new composites or new future experiences.</Typography>
        <Typography mt={2}>
        This is establishes a new chapter in r4v3n’s generative art practice as he embraces the full medium of the blockchain to create new experiences for new and seasoned collectors alike. 
        </Typography>
      </Box>
      </Grid>
      </Grid>
    </Page>
  )
}

export default LandingPage
 