import React, { useEffect, useState } from "react";
import "./DotSelector.css";
import { DOTS_CONTRACT_DETAILS } from "contractConfig";
import { Button } from "@mui/material";
import { DragAndDrop } from "./DragAndDrop";
import { Dot, Project } from "utils/types";
import CompositePreview from "./CompositePreview";
import { MintCompositeButton } from "./MintCompositeButton";
import { Link } from "react-router-dom";

interface DotSelectorProps {
  dots: Dot[];
  address: string;
  compositeProjectData: Project;
}

const DotSelector: React.FC<DotSelectorProps> = ({ dots, address, compositeProjectData }) => {
  const [step, setStep] = useState(1);

  const [selectedDots, setSelectedDots] = useState<Dot[]>(() => {
    const saved = localStorage.getItem('selectedDots');
    return saved ? JSON.parse(saved) : [];
  });
  const [dotsNotSelected, setNotSelectedDots] = useState<Dot[]>(() => {
    const saved = localStorage.getItem('notSelectedDots');
    return saved ? JSON.parse(saved) : [...dots];
  });

  useEffect(() => {
    localStorage.setItem('selectedDots', JSON.stringify(selectedDots));
    localStorage.setItem('notSelectedDots', JSON.stringify(dotsNotSelected));
  }, [selectedDots, dotsNotSelected]);

  useEffect(() => {
    const dotsInState = selectedDots.concat(dotsNotSelected);
    const dotsInStateIds = dotsInState.map((dot) => dot.tokenId).sort();
    const dotsIds = dots.map((dot) => dot.tokenId).sort();

    if (JSON.stringify(dotsInStateIds) !== JSON.stringify(dotsIds)) {
      setNotSelectedDots(dots);
      setSelectedDots([]);
    }
  }, [dots, dotsNotSelected, selectedDots]);

  const toggleSelectDot = (dot: Dot): void => {
    if (selectedDots.includes(dot)) {
      setSelectedDots(selectedDots.filter((item) => item !== dot));
      setNotSelectedDots([...dotsNotSelected, dot]);
    } else {
      setSelectedDots([...selectedDots, dot]);
      setNotSelectedDots(dotsNotSelected.filter((item) => item !== dot));
    }
  };

  const selectAllDots = () => {
    // Add all dots to selectedDots and clear dotsNotSelected
    setSelectedDots([...selectedDots, ...dotsNotSelected]);
    setNotSelectedDots([]);
  };

  const clearSelection = () => {
    setNotSelectedDots([...selectedDots, ...dotsNotSelected]);
    setSelectedDots([]);
  };

  const goToNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const goToPreviousStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const onSuccess = () => {
    localStorage.removeItem('selectedDots');
    localStorage.removeItem('notSelectedDots');

    goToNextStep();
  }

  const enoughDotsSelected = selectedDots.length > 11;

  return (
    <div className="dot-selector">
      {/* Step Indicator */}
      <h2>
        Step {step}: {step === 1 ? "Select" : step === 2 ? "Order" : "Preview & Mint"}
      </h2>

      {/* Conditional Rendering Based on the Current Step */}
      {step === 1 && (
        <>
          {/* Select All Button */}
          {dotsNotSelected.length > 0 ? (
            <Button size="large" onClick={selectAllDots}>
              Select All
            </Button>
          ) : null}
          {/* Dots Selection Grid */}
          <div className="dot-selector-grid">
            {/* Selection Grid */}
            {dotsNotSelected.map((dot: Dot, index: number) => (
              <div
                key={index}
                className={`dot-selector-item`}
                style={{
                  backgroundImage: `url(${DOTS_CONTRACT_DETAILS["MEDIA_URL"]}/thumb/${dot.tokenId}.png)`,
                }}
                onClick={() => toggleSelectDot(dot)}
              />
            ))}
          </div>
          <h3>
            Selected:{" "}
            {selectedDots.length > 11
              ? selectedDots.length
              : `${selectedDots.length}/12`}
          </h3>
          {selectedDots.length > 0 ? (
            <Button onClick={clearSelection}>Clear Selection</Button>
          ) : null}
          {/* Selected Dots Grid */}
          <div className="dot-selected-grid">
            {selectedDots.map((dot: Dot, index: number) => (
              <div
                key={index}
                className={`dot-selector-item ${
                  selectedDots.includes(dot) ? "selected" : ""
                }`}
                style={{
                  backgroundImage: `url(${DOTS_CONTRACT_DETAILS["MEDIA_URL"]}/thumb/${dot.tokenId}.png)`,
                }}
                onClick={() => toggleSelectDot(dot)}
              />
            ))}
          </div>
        </>
      )}

      {step === 2 && (
        <>
          <h3>Drag to Order:</h3>
          <div
            style={{
              display: "grid",
              gap: "0",
              gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
              maxHeight: '55vh',
              marginBottom: '30px',
              overflow: "auto",
            }}
          >
            <DragAndDrop
              items={selectedDots}
              onDragEnd={setSelectedDots}
              render={(dot, i: number) => (
                <div style={{ textAlign: "center" }} key={i}>
                  <img
                    src={`${DOTS_CONTRACT_DETAILS["MEDIA_URL"]}/thumb/${dot.tokenId}.png`}
                    alt={`dot ${dot.tokenId}`}
                    style={{ width: "100px", height: "100px" }}
                  />
                </div>
              )}
            />
          </div>
        </>
      )}

      {step === 3 && (
        <div style={{textAlign: 'center'}}>
          {/* Minting Logic Here */}
          <CompositePreview dots={selectedDots} />
        </div>
      )}

      {step === 4 && (
        <div>
          <h1>Success!</h1>
          <p>Your composite has been minted!</p>
          <Link to={`https://opensea.io/assets/arbitrum/0x0d39ab55664007ff2d089a25480f169c6d0597bb`}>View Collection on OpenSea</Link>
        </div>
      )}

      {/* Navigation Buttons */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        {step > 1 && (
          <div className="dot-selector-button">
            <Button
              className="dot-selector-button"
              size="large"
              variant="outlined"
              onClick={goToPreviousStep}
            >
              Back
            </Button>
          </div>
        )}

        {step < 3 && (
          <div className="dot-selector-button">
            <Button
              disabled={selectedDots.length > 11 ? false : true}
              size="large"
              variant="contained"
              onClick={goToNextStep}
            >
              {enoughDotsSelected
                ? "Next"
                : "Select 12 or More Dots to Continue"}
            </Button>
          </div>
        )}

        {step === 3 && (
          <div className="dot-selector-button">
            <MintCompositeButton dots={selectedDots} address={address} paused={compositeProjectData.paused && address !== compositeProjectData.artistAddress} onSuccess={onSuccess} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DotSelector;
