import {
  usePrepareContractWrite,
  useContractWrite,
  useContractRead,
  useWaitForTransaction,
} from "wagmi";
import { Button } from "@mui/material";
import { Dot } from "utils/types";
import { DOTS_CONTRACT_DETAILS, ENGINE_CONTRACT_DETAILS } from "contractConfig";
import { checksumAddress } from "viem";
import { useState } from "react";

interface Props {
  dots: Dot[];
  address: string;
  paused: boolean;
  onSuccess: Function;
}

const redeemDotsAbi = [{
    inputs: [
      {
        internalType: "contract IGenArt721CoreV3EngineToken",
        name: "targetEngine",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "targetProjectId",
        type: "uint256"
      },
      {
        internalType: "uint256[]",
        name: "dotTokenIds",
        type: "uint256[]"
      }
    ],
    name: "redeemDots",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  }];

export const MintCompositeButton = ({ dots, address, paused, onSuccess }: Props) => {
  const dotCoreAddress = checksumAddress(
    DOTS_CONTRACT_DETAILS["CORE_CONTRACT_ADDRESS"] as `0x${string}`
  );
  const engineMintAddress = checksumAddress(
    ENGINE_CONTRACT_DETAILS["MINT_CONTRACT_ADDRESS"] as `0x${string}`
  );
  const engineCoreAddress = checksumAddress(
    ENGINE_CONTRACT_DETAILS["CORE_CONTRACT_ADDRESS"] as `0x${string}`
  );
  const walletAddress = checksumAddress(address as `0x${string}`);
  const [loading, setLoading] = useState(false);

  const dotsContractWriteConfig = usePrepareContractWrite({
    address: dotCoreAddress,
    args: [engineMintAddress, true],
    abi: [
        {
            inputs: [
            {
                internalType: "address",
                name: "operator",
                type: "address",
            },
            {
                internalType: "bool",
                name: "approved",
                type: "bool",
            },
            ],
            name: "setApprovalForAll",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        ],
    functionName: "setApprovalForAll",
  }).config;

  const minterContractWriteConfig = usePrepareContractWrite({
    address: engineMintAddress,
    args: [engineCoreAddress, 0, dots.map((dot) => dot.tokenId)],
    abi: redeemDotsAbi,
    functionName: "redeemDots",
  }).config;

  const setApprovalForAll = useContractWrite(dotsContractWriteConfig);
  const redeemDots = useContractWrite(minterContractWriteConfig);

  const waitForSetApprovalForAll = useWaitForTransaction({
    hash: setApprovalForAll.data?.hash,
  });

  const waitForRedeemDots = useWaitForTransaction({
    hash: redeemDots.data?.hash,
    onSuccess: () => onSuccess(),
  });

  const onMintClick = async () => {
    try {
      // Check approval
      if (!isApproved) {
        // Request approval
        setApprovalForAll.write?.();
        setLoading(true);
        await waitForApproval();
        setLoading(false);
      }

      redeemDots.write?.();
      // Handle success, e.g., show a success message
    } catch (error) {
        console.log(error)
      // Handle errors, e.g., transaction failed
    }
  };

  let { data: isApproved, isError: isAppovalReadError, isLoading: isApprovalReadLoading, refetch: refetchApproval } = useContractRead({
    address: dotCoreAddress,
    abi: [
      {
        inputs: [
          {
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            internalType: "address",
            name: "operator",
            type: "address",
          },
        ],
        name: "isApprovedForAll",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
    args: [walletAddress, engineMintAddress],
    functionName: "isApprovedForAll",
    chainId: 42161,
  });

  const waitForApproval = async () => {
    while (!isApproved) { 
      await new Promise(resolve => setTimeout(resolve, 2000));
      isApproved = (await refetchApproval()).data; // Trigger refetch on each poll
    }
  }

  // Rest of your component...
  if (paused) {
    return (
      <Button size="large" className="dot-selector-button" variant="contained" disabled>
        Minting is paused
      </Button>
    );  
  } else if (isAppovalReadError) {
    return (
      <Button size="large" className="dot-selector-button" variant="contained" disabled>
        Error
      </Button>
    )
  } else  {
    return (
      <Button
        size="large"
        className="dot-selector-button"
        variant="contained"
        onClick={onMintClick}
        disabled={
          loading || isApprovalReadLoading || waitForSetApprovalForAll.isLoading || waitForRedeemDots.isLoading
        }
      >
        {loading ||waitForRedeemDots.isLoading || waitForSetApprovalForAll.isLoading ? "Minting..." : "Mint"}
      </Button>
    );
  }
};
