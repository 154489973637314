import {
    Alert,
    Box, Breadcrumbs, Grid, Link, Typography,
  } from "@mui/material"
  import useOwnedTokens from "hooks/useOwnedTokens"
  import { ENGINE_CONTRACT_DETAILS } from "contractConfig"
  import Loading from "./Loading"
import BurnSelector from "./BurnSelector"
  
  interface Props {
    walletAddress: string
  }
  
  const compositeProjectId = `${ENGINE_CONTRACT_DETAILS['CORE_CONTRACT_ADDRESS']}-0`.toLowerCase();
  
  const Burn = ({ walletAddress }: Props) => {
  
  
    let tokensLoading, tokensFetchError, tokens;
  
    try {
      const result1 = useOwnedTokens(compositeProjectId, walletAddress, {});
      tokensLoading = result1.loading;
      tokensFetchError = result1.error;
      tokens = result1.tokens;
    } catch (e) {
      tokensFetchError = e;
    }
  
  
    if (tokensLoading) {
      return <Loading />;
    }
  
    if (tokensFetchError) {
      return (
        <Alert severity="error">
          Error loading tokens.
        </Alert>
      );
    }
  
    if (!tokens || tokens.length === 0) {
      return (
        <Alert severity="info">
          No eligible tokens found.
        </Alert>
      )
    }
  
     return (
      <Box>
        <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom: 4}}>
          <Link href="/projects" underline="hover" sx={{color: "#666"}}>
            Home
          </Link>
          <Typography>
            Burn
          </Typography>
        </Breadcrumbs>
        <Grid spacing={2} container>
         <Grid item md={12} xs={12} sm={12}>
            <BurnSelector tokens={tokens} address={walletAddress} />
         </Grid>
        </Grid>
      </Box>
    )
  }
  
  export default Burn