import { useEffect, useRef } from "react";
import { drawCompositePreview } from "utils/composite";
import { Dot } from "utils/types"

interface Props {
  dots: Dot[]
}

const CompositePreview = ({ dots }: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    console.log(dots)

    useEffect(() => {
        if (!containerRef.current) return;

        containerRef.current.innerHTML = '';
        drawCompositePreview(containerRef.current, dots);
    }, [dots])

    return (
        <div ref={containerRef} style={{width: '100%', height: '60vh'}}>
        </div>
    )
}

export default CompositePreview