import Page from "components/Page";
import CompositeMinter from "components/CompositeMinter";
import { useAccount } from "wagmi";
import { Alert } from "@mui/material";

const CompositeMinterPage = () => {
  const { address } = useAccount();

  if (!address) {
    return (
      <Page>
        {
          <Alert severity="info">
            Please connect your wallet to build a composite.
          </Alert>
        }
      </Page>
    );
  }

  return (
    <Page>{<CompositeMinter walletAddress={address.toLowerCase()} />}</Page>
  );
};

export default CompositeMinterPage;
