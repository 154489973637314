import { useState, useEffect } from 'react';
import { useApolloClient, gql } from '@apollo/client';
import { TOKENS_PER_PAGE } from 'config';
import { OrderDirection } from 'utils/types';

interface Token {
  id: string;
  tokenId: string;
  invocation: string;
  hash: string;
}

interface Params {
  orderDirection?: OrderDirection;
}

const ownedTokensQuery = gql`
  query GetTokens($projectId: String!, $walletAddress: String!, $first: Int!, $skip: Int!, $orderDirection: OrderDirection) {
    tokens(
      first: $first,
      skip: $skip,
      orderBy: createdAt, 
      orderDirection: $orderDirection,
      where: {
        project: $projectId,
        owner: $walletAddress
      }
    ) {
      id
      tokenId
      invocation
      hash
    }
  }`;

const useOwnedTokens = (projectId: string, walletAddress: string, { orderDirection = OrderDirection.ASC }: Params) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [tokens, setTokens] = useState<Token[]>([]);
  const client = useApolloClient();

  useEffect(() => {
    const fetchAllTokens = async () => {
      try {
        setLoading(true);
        let fetchedData: Token[] = [];
        let skip = 0;
        let shouldFetchMore = true;

        while (shouldFetchMore) {
          const { data, errors } = await client.query({
            query: ownedTokensQuery,
            variables: { projectId, walletAddress, first: TOKENS_PER_PAGE, skip, orderDirection },
          });

          if (errors) {
            setError(new Error('Error fetching tokens'));
            break;
          }

          fetchedData = [...fetchedData, ...data.tokens];
          if (data.tokens.length < TOKENS_PER_PAGE) {
            shouldFetchMore = false;
          } else {
            skip += TOKENS_PER_PAGE;
          }
        }

        setTokens(fetchedData);
      } catch (e) {
        setError(e instanceof Error ? e : new Error('An error occurred'));
      } finally {
        setLoading(false);
      }
    };

    fetchAllTokens();
  }, [client, projectId, walletAddress, orderDirection]);

  return { loading, error, tokens };
};

export default useOwnedTokens;