// COMPONENT:
import React, { ReactNode, useState } from "react";

type Props<T> = {
  items: T[];
  render(item: T, index: number): ReactNode;
  onDragEnd(newItems: T[]): void;
};

export const DragAndDrop = <T,>({ items, render, onDragEnd }: Props<T>) => {
  const [temporaryItems, setTemporaryItems] = useState<T[]>();
  const [draggedItem, setDragedItem] = useState<T>();

  const renderedItems = temporaryItems || items;

  return (
    <>
      {renderedItems.map((item, index) => (
        <div
          key={index}
          style={{ cursor: "grab", ...(item === draggedItem ? { opacity: "0.4", border: "2px dashed grey" } : {})}}
          draggable
          onDragStart={() => setDragedItem(item)}
          onDragOver={(e) => {
            e.preventDefault();
            if (!draggedItem || draggedItem === item) {
              return;
            }
            const currentIndex = renderedItems.indexOf(draggedItem);
            const targetIndex = renderedItems.indexOf(item);

            if (currentIndex !== -1 && targetIndex !== -1) {
              const newItems = [...renderedItems];
              newItems.splice(currentIndex, 1);
              newItems.splice(targetIndex, 0, draggedItem);
              setTemporaryItems(newItems);
            }
          }}
          onDragEnd={() => {
            onDragEnd(renderedItems);
            setDragedItem(undefined);
            setTemporaryItems(undefined);
          }}
        >
          {render(item, index)}
        </div>
      ))}
    </>
  );
}