import React, { useState } from "react";
import "./BurnSelector.css";
import { ENGINE_CONTRACT_DETAILS } from "contractConfig";
import { Link } from "@mui/material";
import { BurnButton } from "./BurnButton";

interface Token {
  id: string;
  tokenId: string;
  invocation: string;
  hash: string;
}

interface BurnSelectorProps {
  tokens: Token[];
  address: string;
}

const BurnSelector: React.FC<BurnSelectorProps> = ({ tokens, address }) => {
  const [step, setStep] = useState(1);

  const goToNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  return (
    <div className="burn-selector">
      {/* Conditional Rendering Based on the Current Step */}
      {step === 1 && (
        <>
          <div className="burn-grid">
            {tokens.map((token: Token, index: number) => (
              <div key={index}>
              <div
                className={`burn-token-item`}
                style={{
                  backgroundImage: `url(${ENGINE_CONTRACT_DETAILS["MEDIA_URL"]}/thumb/${token.tokenId}.png)`,
                }}
              />
              <BurnButton token={token} address={address} onSuccess={goToNextStep} />
            </div>
            ))}
          </div>
        </>
      )}

      {step === 2 && ( 
        <>
          <h1>Successfully Burned! 🔥🔥🔥</h1>
          <p>Dots have been released into your wallet. Enjoy!</p>
          <Link href="https://prohibition.art/project/dots">
            View them on Prohibition
          </Link>
        </>
      )}
    </div>
  );
};

export default BurnSelector;
