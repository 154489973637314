export const mainnetContractConfig = [
  {
    "CONTRACT_VERSION": "V3",
    "CORE_CONTRACT_ADDRESS": "0x47a91457a3a1f700097199fd63c039c4784384ab",
    "MINT_CONTRACT_ADDRESS": "0x47Ec4768502ADA411bce8b973a6609f3F96061f3",
    "MEDIA_URL": "https://prohibition-arbitrum.s3.amazonaws.com",
    "TOKEN_URL": "https://token.arbitrum.artblocks.io/0x47a91457a3a1f700097199fd63c039c4784384ab",
    "GENERATOR_URL": "https://generator.arbitrum.artblocks.io",
    "EDIT_PROJECT_URL": 'https://creator.artblocks.io',
  },
  {
    "CONTRACT_VERSION": "V3",
    "CORE_CONTRACT_ADDRESS": "0x0D39AB55664007ff2d089A25480f169C6D0597Bb",
    "MINT_CONTRACT_ADDRESS": "0x89EF10d7A72281077662464E0295e7C603293e87",
    "MEDIA_URL": "https://media-proxy-arbitrum.artblocks.io/0x0D39AB55664007ff2d089A25480f169C6D0597Bb",
    "TOKEN_URL": "https://token.arbitrum.artblocks.io/0x0D39AB55664007ff2d089A25480f169C6D0597Bb",
    "GENERATOR_URL": "https://generator.arbitrum.artblocks.io",
    "EDIT_PROJECT_URL": 'https://creator.artblocks.io',
  },
]

export const testnetContractConfig = [
  {
    "CONTRACT_VERSION": "V3",
    "CORE_CONTRACT_ADDRESS": "0x62e37f664b5945629b6549a87f8e10ed0b6d923b",
    "MINT_CONTRACT_ADDRESS": "0x23ff83f3891127025b465c526f8d381659887575",
    "MEDIA_URL": "https://media-proxy-staging.artblocks.io/0x639ae7d29e70f32827826b8d6ff031a93c2c02b7",
    "TOKEN_URL": "https://token.staging.artblocks.io",
    "GENERATOR_URL": "https://generator-staging-goerli.artblocks.io",
    "EDIT_PROJECT_URL": 'https://creator.artblocks.io',
  },
]

export const DOTS_CONTRACT_DETAILS = mainnetContractConfig[0];
export const ENGINE_CONTRACT_DETAILS = mainnetContractConfig[1];