import Page from "components/Page";
import { useAccount } from "wagmi";
import { Alert } from "@mui/material";
import Burn from "components/Burn";

const BurnPage = () => {
  const { address } = useAccount();

  if (!address) {
    return (
      <Page>
        {
          <Alert severity="info">
            Please connect your wallet to burn a composite.
          </Alert>
        }
      </Page>
    );
  }

  return <Page>{<Burn walletAddress={address.toLowerCase()} />}</Page>;
};

export default BurnPage;