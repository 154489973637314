import CssBaseline from "@mui/material/CssBaseline"
import "@rainbow-me/rainbowkit/styles.css"
import { ThemeProvider } from "@mui/material/styles"
import theme from "theme"
import { RainbowKitProvider, getDefaultWallets, midnightTheme } from "@rainbow-me/rainbowkit"
import { WagmiConfig, createConfig, configureChains } from 'wagmi'
import { arbitrum, arbitrumSepolia } from 'wagmi/chains'
import { infuraProvider } from "wagmi/providers/infura"
import { publicProvider } from "wagmi/providers/public"
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client"
import { GRAPHQL_URL, INFURA_KEY, EXPECTED_CHAIN_ID, WALLET_CONNECT_PROJECT_ID, MAINNET_CHAIN_ID } from "config"

const client = new ApolloClient({
  uri: GRAPHQL_URL,
  cache: new InMemoryCache()
})

// Defaults to arb-sepolia testing network if mainnet is not set
const expectedChains = [EXPECTED_CHAIN_ID === MAINNET_CHAIN_ID ? arbitrum : arbitrumSepolia]
const initialChain = EXPECTED_CHAIN_ID === MAINNET_CHAIN_ID ? arbitrum : arbitrumSepolia

const { chains, publicClient, webSocketPublicClient } = configureChains(
  expectedChains,
  [
    infuraProvider({apiKey: INFURA_KEY}),
    publicProvider()
  ]
)

const { connectors } = getDefaultWallets({
  appName: "Dots",
  chains,
  projectId: WALLET_CONNECT_PROJECT_ID
})

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient
})

interface Props {
  children: React.ReactNode
}

const AppProvider = ({children}:Props) => {
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider
         chains={chains}
         initialChain={initialChain}
         theme={
          midnightTheme({
            borderRadius: "medium"
          })
        }>
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
          </ThemeProvider>
        </ApolloProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  )
}

export default AppProvider
