import {
  Alert,
  Box, Breadcrumbs, Grid, Link, Typography,
} from "@mui/material"
import DotSelector from "./DotSelector"
import useOwnedTokens from "hooks/useOwnedTokens"
import { DOTS_CONTRACT_DETAILS, ENGINE_CONTRACT_DETAILS } from "contractConfig"
import Loading from "./Loading"
import useProject from "hooks/useProject"

interface Props {
  walletAddress: string
}

  const dotsProjectId = `${DOTS_CONTRACT_DETAILS['CORE_CONTRACT_ADDRESS']}-301`;
  const compositeProjectId = `${ENGINE_CONTRACT_DETAILS['CORE_CONTRACT_ADDRESS']}-0`;

const CompositeMinter = ({ walletAddress }: Props) => {


  let dotsLoading, dotsFetchError, dotsTokens;
  let compositeLoading, compositeFetchError, compositeData;

  try {
    const result1 = useOwnedTokens(dotsProjectId, walletAddress, {});
    dotsLoading = result1.loading;
    dotsFetchError = result1.error;
    dotsTokens = result1.tokens;
  } catch (e) {
    dotsFetchError = e;
  }

  try {
    const result2 = useProject(compositeProjectId);
    compositeData = result2.loading;
    compositeFetchError = result2.error;
    compositeData = result2.data?.project;
  } catch (e) {
    compositeFetchError = e;
  }

  if (dotsLoading || compositeLoading) {
    return <Loading />;
  }

  if (dotsFetchError || compositeFetchError) {
    return (
      <Alert severity="error">
        Error loading dots.
      </Alert>
    );
  }

  if (!dotsTokens || dotsTokens.length === 0) {
    return (
      <Alert severity="info">
        No dots found.
      </Alert>
    )
  }

   return (
    <Box>
      <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom: 4}}>
        <Link href="/projects" underline="hover" sx={{color: "#666"}}>
          Home
        </Link>
        <Typography>
          Dots Composite Builder
        </Typography>
      </Breadcrumbs>
      <Grid spacing={2} container>
       <Grid item md={12} xs={12} sm={12}>
        <DotSelector compositeProjectData={compositeData} dots={dotsTokens} address={walletAddress} />
       </Grid>
      </Grid>
    </Box>
  )
}

export default CompositeMinter