import {
    usePrepareContractWrite,
    useContractWrite,
    useContractRead,
    useWaitForTransaction,
  } from "wagmi";
  import { Button } from "@mui/material";
  import { ENGINE_CONTRACT_DETAILS } from "contractConfig";
  import { checksumAddress } from "viem";
  import { useState } from "react";
  import "./BurnSelector.css";
 
  interface Token {
    id: string;
    tokenId: string;
    invocation: string;
    hash: string;
  }

  interface Props {
    token: Token;
    address: string;
    onSuccess: Function;
  }
 
  const recoverDotsAbi = [{
    inputs: [
      {
        internalType: "contract IGenArt721CoreV3EngineToken",
        name: "targetEngine",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "targetTokenId",
        type: "uint256"
      }
    ],
    name: "recoverDots",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  }];


  export const BurnButton = ({ token, address, onSuccess }: Props) => {
    const engineMintAddress = checksumAddress(
      ENGINE_CONTRACT_DETAILS["MINT_CONTRACT_ADDRESS"] as `0x${string}`
    );
    const engineCoreAddress = checksumAddress(
      ENGINE_CONTRACT_DETAILS["CORE_CONTRACT_ADDRESS"] as `0x${string}`
    );
    const walletAddress = checksumAddress(address as `0x${string}`);
    const [loading, setLoading] = useState(false);
  
    const engineContractWriteConfig = usePrepareContractWrite({
      address: engineCoreAddress,
      args: [engineMintAddress, true],
      abi: [
          {
              inputs: [
              {
                  internalType: "address",
                  name: "operator",
                  type: "address",
              },
              {
                  internalType: "bool",
                  name: "approved",
                  type: "bool",
              },
              ],
              name: "setApprovalForAll",
              outputs: [],
              stateMutability: "nonpayable",
              type: "function",
          },
          ],
      functionName: "setApprovalForAll",
    }).config;
  
    const minterContractWriteConfig = usePrepareContractWrite({
      address: engineMintAddress,
      args: [engineCoreAddress, token.tokenId],
      abi: recoverDotsAbi,
      functionName: "recoverDots",
    }).config;
  
    const setApprovalForAll = useContractWrite(engineContractWriteConfig);
    const recoverDots = useContractWrite(minterContractWriteConfig);
  
    const waitForSetApprovalForAll = useWaitForTransaction({
      hash: setApprovalForAll.data?.hash,
    });
  
    const waitForRecoverDots = useWaitForTransaction({
      hash: recoverDots.data?.hash,
      onSuccess: () => onSuccess(),
    });
  
    const onBurnClick = async () => {
      try {
        // Check approval
        if (!isApproved) {
          // Request approval
          setApprovalForAll.write?.();
          setLoading(true);
          await waitForApproval();
          setLoading(false);
        }
  
        recoverDots.write?.();
        // Handle success, e.g., show a success message
      } catch (error) {
          console.log(error)
        // Handle errors, e.g., transaction failed
      }
    };
  
    let { data: isApproved, isError: isAppovalReadError, isLoading: isApprovalReadLoading, refetch: refetchApproval } = useContractRead({
      address: engineCoreAddress,
      abi: [
        {
          inputs: [
            {
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              internalType: "address",
              name: "operator",
              type: "address",
            },
          ],
          name: "isApprovedForAll",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
      args: [walletAddress, engineMintAddress],
      functionName: "isApprovedForAll",
      chainId: 42161,
    });
  
    const waitForApproval = async () => {
      while (!isApproved) { 
        await new Promise(resolve => setTimeout(resolve, 2000));
        isApproved = (await refetchApproval()).data; // Trigger refetch on each poll
      }
    }
  
    // Rest of your component...
    if (isAppovalReadError) {
      return (
        <Button size="large" className="dot-selector-button" variant="contained" disabled>
          Error
        </Button>
      )
    } else  {
      return (
        <Button
          size="large"
          className="burn-token-button"
          variant="contained"
          onClick={onBurnClick}
          disabled={
            loading || isApprovalReadLoading || waitForSetApprovalForAll.isLoading || waitForRecoverDots.isLoading
          }
        >
          {loading ||waitForRecoverDots.isLoading || waitForSetApprovalForAll.isLoading ? "Burning... 🔥🔥🔥" : "Burn 🔥"}
        </Button>
      );
    }
  };
  