import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LandingPage from "pages/LandingPage";
import Providers from "components/Providers";
import CompositeMinterPage from "pages/CompositeMinterPage";
import ProjectPage from "pages/ProjectPage";
import TokenPage from "pages/TokenPage";
import BurnPage from "pages/BurnPage";

function App() {
  return (
    <Providers>
      <Router>
        <Routes>
          <Route index element={<LandingPage />} />
          <Route path="builder" element={<CompositeMinterPage />} />
          <Route
            path="burn/:contractAddress/:projectId"
            element={<BurnPage />}
          />
          <Route
            path="project/:contractAddress/:projectId"
            element={<ProjectPage />}
          />
          <Route path="token/:contractAddress/:id" element={<TokenPage />} />
        </Routes>
      </Router>
      <ToastContainer
        autoClose={10000}
        position="bottom-right"
        theme="dark"
        newestOnTop
        pauseOnHover
        pauseOnFocusLoss
      />
    </Providers>
  );
}

export default App;
